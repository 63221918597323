:root {
  color: #000000;
  --color: #000000;
  --app-color: #000000;
  --app-main-color: rgb(241, 244, 248);

  --app-sep-color: rgba(92, 92, 235, 0.18);

  --app-tab-background: #f8f8f7;
  --app-tab-color: #999999;

  --app-nav-background: #f8f8f7;
  --app-nav-color: #000000;
  --app-nav-gray: #999999;
  --app-nav-bottom: #adadad;
  --app-nav-map-bg: linear-gradient(
    180deg,
    rgba(248, 248, 247, 1) 20%,
    rgba(255, 255, 255, 0) 100%
  );

  --app-segment-bg: #e7e6e7;
  --app-segment-indicator: #ffffff;
  --app-segment-color: #000000;

  --app-float-btn-bg: rgba(249, 249, 249, 1);
  --app-popover-bg: #ffffff;

  --app-sep-color: rgba(120, 120, 128, 0.36);

  --app-card-bg: #f9f9f9;

  --app-progressbar-bg: rgba(0, 0, 0, 0.04);

  /* 字 */
  --app-text-light: #adadad;
  --app-text-gray: #999999;

  --app-input-border-color: #999999;

  --app-float-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

  --grid-bottom-img: url("/assets/bg/bg-grid-bottom-white.png");
}

app-grid-card {
  --grid-type-bg: #ff9500;
  --grid-type-color: #ffffff;
}

app-pollution-factor {
  --table-header-bg: #0076ff;
  --table-header-color: #ffffff;
}

app-grid-feedback {
  --add-remark-bg: #e3e3e3;
  --add-remark-activated-bg: #d3d3d3;
  --aqi-card-bg: #f5f5f5;
}

app-my-message {
  --time-marker-bg: rgba(198, 198, 200, 0.3);
  --message-card-bg: #eeeeee;
  --message-detail-btn: #dfdfdf;
}

app-take-photo {
  --container-bg: #e3e3e3;
}

app-area-pollution-rank {
  --table-header-bg: rgba(10, 132, 255, 0.12);
  --table-header-color: rgba(0, 0, 0, 0.7);
  --table-body-banner-bg: rgba(0, 0, 0, 0.03);
}

.app-modal {
  --backdrop-opacity: 0.4;
  --background: transparent;
}
